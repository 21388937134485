/* eslint-disable react/no-array-index-key */
import { CloseIcon, LogoImg, LogoutIcon } from '@/assets';
import { menuSidebar } from '@/components/Layout/constants';
import { DialogSignOutMetamask } from '@/components/PageHeader';
import { hooks, metaMask } from '@/connectors/metaMask';
import { useToggleValue } from '@/hooks';
import { UserLogout } from '@/store/auth/actions';
import { userProfile } from '@/store/user/saga';
import { injected } from '@/utils/walletConnector';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Spin } from 'antd';
import { CloseCircle, HambergerMenu } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const { useIsActivating } = hooks;

const HeaderMobile = () => {
  const [menuHeader, toggleMenuHeader] = useToggleValue(false);
  const role = userProfile();
  const location = useLocation();
  const context = useWeb3React<Web3Provider>();
  const { connector, account } = context;
  const isActiving = useIsActivating();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    metaMask.connectEagerly().catch((error) => {
      console.debug('Failed to connect eagerly to metamask');
    });
  }, []);
  const connectWallet = async () => {
    try {
      await connector.activate(injected);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="header-mobile">
      <img src={LogoImg} alt="" />
      <div className="menu-header">
        <div className="action" onClick={toggleMenuHeader}>
          {menuHeader ? <CloseIcon /> : <HambergerMenu color="#f2f4f7" />}
        </div>
        {menuHeader && (
          <div className="menu-link">
            <div className="wallet">
              <p className="label">Wallet</p>
              <div className="connect-wallet">
                {account ? (
                  <div className="action">
                    <span className="code">
                      {account.slice(0, 5)}...{account.slice(-5)}
                    </span>
                    <CloseCircle color="#475467" variant="Bold" onClick={() => setOpenDialog(true)} />
                  </div>
                ) : (
                  <>
                    {isActiving ? (
                      <Spin />
                    ) : (
                      <p className="btn-connect" onClick={connectWallet}>
                        Connect wallet
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            {menuSidebar.map((item: any, index: number) => {
              if (item.roles.includes(role)) {
                if (item.is_group) {
                  return (
                    <div className="group-item" key={index}>
                      <p className="title">{item.title}</p>
                      {item?.items?.map((menuChild: any, childIndex: number) => {
                        const itemIcon = menuChild?.icon ? <menuChild.icon /> : <></>;
                        return (
                          <Link
                            to={menuChild.path}
                            className={`item-link ${location.pathname.includes(menuChild.path) ? 'active' : ''}`}
                            onClick={toggleMenuHeader}
                            key={childIndex}
                          >
                            <div className="icon">{itemIcon}</div>
                            <p className="label">{menuChild.title}</p>
                          </Link>
                        );
                      })}
                    </div>
                  );
                }
              }
              return <></>;
            })}
            <div className="group-item">
              <div className="item-link" onClick={() => dispatch(UserLogout())}>
                <div className="icon">
                  <LogoutIcon />
                </div>
                <p className="label">Logout</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {openDialog && <DialogSignOutMetamask open={openDialog} close={() => setOpenDialog(false)} />}
    </div>
  );
};

export default React.memo(HeaderMobile);
