import React from 'react';

import { Modal, ModalProps } from 'antd';

import { CloseCircle } from 'iconsax-react';

interface PopupCustomProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
  isCloseIcon?: boolean;
  isPopupToast?: boolean;
}

const PopupCustom = ({
  isOpen,
  onClose,
  className = '',
  isCloseIcon = false,
  isPopupToast = false,
  children,
  ...rest
}: PopupCustomProps) => {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      className={`titan-popup ${isPopupToast ? '' : 'popup-titan-mobile'} ${className}`}
      closeIcon={isCloseIcon ? <CloseCircle color="#475467" variant="Bold" /> : false}
      centered
      footer={null}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default React.memo(PopupCustom);
