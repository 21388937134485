import React, { useLayoutEffect, useRef, useState } from 'react';

import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@/assets';
import type { PaginationProps } from 'antd';
import { Pagination, Select, Table } from 'antd';
import type { TablePaginationConfig, TableProps } from 'antd/es/table';

import { OptionPagination } from '@/constants';
import { useEffectOnlyOnce } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { EmptyData } from './EmptyData';
import { handleStringify } from '@/utils';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
type IPropsTableData = {
  isEmpty?: boolean;
  sorters?: {
    field?: string;
    order?: string;
  };
} & TableProps<any>;

type OptionsSizeChange = {
  label: string;
  value: string;
}[];

export const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <ChevronLeftIcon />;
  }
  if (type === 'next') {
    return <ChevronRightIcon />;
  }
  return originalElement;
};

export const TableData: React.FC<IPropsTableData> = ({ isEmpty, sorters, ...rest }) => {
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const [optionsSizeChange, setOptionsSizeChange] = useState([] as OptionsSizeChange);
  const { onChange } = rest;
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  useEffectOnlyOnce(() => {
    const options = [] as OptionsSizeChange;
    if (rest.pagination && rest.pagination?.pageSizeOptions?.length) {
      rest.pagination?.pageSizeOptions.map((i) =>
        options.push({
          label: `${i.toString()} / ${t('page')}`,
          value: i.toString(),
        }),
      );
    } else {
      OptionPagination.map((i) =>
        options.push({
          label: `${i.toString()} / ${t('page')}`,
          value: i.toString(),
        }),
      );
    }
    setOptionsSizeChange(options);
  });

  const handleSelect = (value: string) => {
    if (onChange) {
      const pagination = {
        current: 1,
        pageSize: parseInt(value),
      };
      onChange(pagination, {}, sorters as {}, {} as any);
    }
  };

  const onChangePagination = (page: number, pageSize: number) => {
    if (onChange) {
      const pagination = {
        current: page,
        pageSize,
      };
      handleStringify(
        {
          ...parsedQuery,
          pageSize: pagination.pageSize,
          current: pagination.current,
        },
        history,
      );
      onChange(pagination, {}, sorters as {}, {} as any);
    }
  };
  const onChangeTable = (_: TablePaginationConfig, filters?: any, sorter?: any) => {
    if (onChange) {
      const pagination = {
        ...rest.pagination,
      };
      onChange(pagination, {}, sorter, {} as any);
    }
  };
  return (
    <>
      <Table
        ref={tableRef}
        {...rest}
        pagination={false}
        onChange={onChangeTable}
        className={`titan-table ${isEmpty && 'titan-table--empty'}`}
        locale={{ emptyText: rest.loading ? ' ' : <EmptyData /> }}
        showSorterTooltip={false}
      />
      {rest.pagination && (
        <div className="titan-pagination" ref={paginationRef}>
          <div className="titan-pagination--action">
            {rest.pagination?.showSizeChanger ? (
              <>
                <Select
                  onChange={handleSelect}
                  value={rest.pagination && rest.pagination?.pageSize ? rest.pagination?.pageSize.toString() : ''}
                  className="titan-pagination--select-size-change"
                  popupClassName="popup-select-size-change"
                  options={optionsSizeChange}
                  suffixIcon={<ChevronDownIcon />}
                />
                <div className="titan-pagination--total">
                  <span className="total-text">{`${t('Total')}:`}</span>
                  <span className="total-result">
                    {rest.pagination?.total} {`${t('results')}`}
                  </span>
                </div>
              </>
            ) : null}
          </div>

          <div className="titan-pagination--action">
            <Pagination
              onChange={onChangePagination}
              current={(rest.pagination && rest.pagination?.current) || 0}
              pageSize={(rest.pagination && rest.pagination?.pageSize) || 0}
              total={(rest.pagination && rest.pagination?.total) || 0}
              itemRender={itemRender}
              showLessItems={false}
              showQuickJumper={
                rest.pagination && rest.pagination?.showQuickJumper
                  ? {
                      goButton: (
                        <div className="titan-pagination--action-go">
                          <ChevronRightIcon />
                        </div>
                      ),
                    }
                  : false
              }
              locale={{ jump_to: `${t('Go to page')}`, page: '' }}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TableData;
