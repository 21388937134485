import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { RouteWithLayout } from '@/components/RouteWithLayout';
import { requestUserProfile } from '@/store/user/actions';

import 'antd/dist/antd.less';
import './App.scss';
import './i18n';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import { DialogError } from './components';
import { routes } from './routes';
import { IsLoggedIn } from './store/auth/selector';
import { userProfile } from './store/user/saga';

export default function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(IsLoggedIn);
  const role = userProfile();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(requestUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <Switch>
        <Redirect from="/" to={role === 'creator' ? '/help-center' : '/user-management/dashboard'} exact />

        {/* <Route exact={true} path="/auth/okta" component={OktaCallback} /> */}
        {routes.map(({ Component, Layout, Protected, routePath, exact, path, roles }, key) => {
          return (
            <RouteWithLayout
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              component={Component}
              exact={exact}
              layout={Layout}
              path={path}
              routePath={routePath}
              protect={Protected}
              roles={roles}
            />
          );
        })}
      </Switch>
      <DialogError />
    </>
  );
}
