import {
  BitcoinConvertIcon,
  CrownIcon,
  DashboardIcon,
  DiscountCircleIcon,
  DocumentTextIcon,
  Money4Icon,
  MoneyReceiveIcon,
  PercentageSquareIcon,
  Profile2UserIcon,
  UserTickIcon,
  BuyCryptoIcon,
  Wallet3Icon,
  BitcoinRefreshIcon,
  AffiliateRequestsIcon,
  TaskManagementIcon,
  CupIcon,
} from '@/assets';
import { UserRole } from '@/constants';
import i18n from '@/i18n';
import {
  ADMIN_TRANSACTION_HISTORY_ROUTER,
  AFFILIATE_MANAGEMENT,
  BALANCE_REPORT_ROUTER,
  COMMISSION_REPORT_ROUTER,
  DASHBOARD_ROUTER,
  HELP_CENTER_SETTING_ROUTER,
  HISTORY_LIST_ROUTER,
  MEMBER_LIST_ROUTER,
  REWARD_REQUEST,
  SALE_REPORT_ROUTER,
  TASK_MANAGEMENT,
  TRANSACTION_REQUEST_TRACKING_ROUTER,
  USER_LIST_ROUTER,
  USER_TRANSACTION_HISTORY_ROUTER,
  VIP_PACKAGE_TRAKING_ROUTER,
  WALLET_LIST_ROUTER,
  WITHDRAWAL_REQUESTS_ROUTER,
} from '@/routes/constants';
import { StarOutlined } from '@ant-design/icons';
export const menuSidebar = [
  {
    id: 1,
    is_group: true,
    title: i18n.t('User management'),
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
    items: [
      {
        id: 1,
        title: i18n.t('Dashboard'),
        path: DASHBOARD_ROUTER,
        icon: DashboardIcon,
        roles: [UserRole.SuperAdmin, UserRole.Marketing],
      },
      {
        id: 2,
        title: i18n.t('User list'),
        path: USER_LIST_ROUTER,
        icon: Profile2UserIcon,
        roles: [UserRole.SuperAdmin, UserRole.Marketing],
      },
      {
        id: 3,
        title: i18n.t('Member list'),
        path: MEMBER_LIST_ROUTER,
        icon: UserTickIcon,
        roles: [UserRole.SuperAdmin, UserRole.Marketing],
      },
    ],
  },

  {
    id: 7,
    is_group: true,
    title: 'Affiliates',
    roles: [UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        title: 'Affiliate management',
        path: AFFILIATE_MANAGEMENT,
        icon: AffiliateRequestsIcon,
        roles: [UserRole.SuperAdmin],
      },
    ],
  },

  {
    id: 2,
    is_group: true,
    title: i18n.t('Request tracking'),
    roles: [UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        title: i18n.t('Withdrawal requests'),
        path: WITHDRAWAL_REQUESTS_ROUTER,
        icon: MoneyReceiveIcon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 2,
        title: i18n.t('VIP package tracking'),
        path: VIP_PACKAGE_TRAKING_ROUTER,
        icon: CrownIcon,
        roles: [UserRole.SuperAdmin],
      },
    ],
  },
  {
    id: 8,
    is_group: true,
    title: 'Reward management',
    roles: [UserRole.Marketing, UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        roles: [UserRole.Marketing, UserRole.SuperAdmin],
        title: 'Task management',
        path: TASK_MANAGEMENT,
        icon: TaskManagementIcon,
      },
      {
        id: 2,
        roles: [UserRole.Marketing, UserRole.SuperAdmin],
        title: 'Reward request',
        path: REWARD_REQUEST,
        icon: CupIcon,
      },
    ],
  },
  {
    id: 3,
    is_group: true,
    title: i18n.t('Hot wallet'),
    roles: [UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        title: i18n.t('Transaction request tracking'),
        path: TRANSACTION_REQUEST_TRACKING_ROUTER,
        icon: BitcoinRefreshIcon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 2,
        title: i18n.t(`Users' transaction history`),
        path: USER_TRANSACTION_HISTORY_ROUTER,
        icon: BuyCryptoIcon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 3,
        title: i18n.t(`Admins' transaction history`),
        path: ADMIN_TRANSACTION_HISTORY_ROUTER,
        icon: BitcoinConvertIcon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 4,
        title: i18n.t(`Wallet list`),
        path: WALLET_LIST_ROUTER,
        icon: Wallet3Icon,
        roles: [UserRole.SuperAdmin],
      },
    ],
  },
  {
    id: 4,
    is_group: true,
    title: i18n.t('Reports'),
    roles: [UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        title: i18n.t('Balance report'),
        path: BALANCE_REPORT_ROUTER,
        icon: Money4Icon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 2,
        title: i18n.t('Commission report'),
        path: COMMISSION_REPORT_ROUTER,
        icon: PercentageSquareIcon,
        roles: [UserRole.SuperAdmin],
      },
      {
        id: 3,
        title: i18n.t('Sale report'),
        path: SALE_REPORT_ROUTER,
        icon: DiscountCircleIcon,
        roles: [UserRole.SuperAdmin],
      },
    ],
  },

  {
    id: 5,
    is_group: true,
    title: i18n.t('History log'),
    roles: [UserRole.SuperAdmin],
    items: [
      {
        id: 1,
        title: i18n.t('History log'),
        path: HISTORY_LIST_ROUTER,
        icon: DocumentTextIcon,
        roles: [UserRole.SuperAdmin],
      },
    ],
  },
  {
    id: 6,
    is_group: true,
    title: 'Help Center',
    roles: [UserRole.Creator],
    items: [
      {
        id: 1,
        roles: [UserRole.Creator],
        title: 'Help Center',
        path: HELP_CENTER_SETTING_ROUTER,
        icon: StarOutlined,
      },
    ],
  },
];
