import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { IsLoggedIn } from '@/store/auth/selector';
import { Spin } from 'antd';
import { userProfile } from '@/store/user/saga';
import { useDispatch } from 'react-redux';
import { commonRequestError } from '@/store/common/actions';
import { useTranslation } from 'react-i18next';
export interface RouteWithLayoutProps {
  component: React.FC<any>;
  layout: React.FC<any>;
  path?: string | string[];
  from?: string;
  to?: string;
  exact?: boolean;
  protect: boolean;
  routePath?: string;
  roles?: any[];
}

export const RouteWithLayout: React.FC<RouteWithLayoutProps> = (props) => {
  const { layout: Layout, component: Component, protect, roles, ...rest } = props;
  const isLoggedIn = useSelector(IsLoggedIn);
  const role = userProfile();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  if (protect && !isLoggedIn) {
    return <Redirect to={`/sign-in?rd=${history.location.pathname}`} />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (protect && roles && !roles.includes(role)) {
          dispatch(
            commonRequestError({
              message: t('common.no_permission_access'),
              description: t('common.no_permission_access_desc'),
              timeout: 1000,
            }),
          );
          return (
            <Layout>
              <Suspense fallback={<></>}></Suspense>
            </Layout>
          );
        }
        return (
          <Layout>
            <Suspense
              fallback={
                !protect ? (
                  <div
                    style={{
                      width: 450,
                      height: 420,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <></>
                )
              }
            >
              <Component {...matchProps} />
            </Suspense>
          </Layout>
        );
      }}
    />
  );
};
