import { Danger } from 'iconsax-react';
import React from 'react';

import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface IPropsInputCurrency extends NumericFormatProps {
  err?: boolean;
  messageError?: string;
}

export const InputCurrency: React.FC<IPropsInputCurrency> = ({ err, messageError, ...rest }) => {
  const renderClass = () => {
    if (err === undefined) return '';
    if (err) {
      return 'error';
    } else {
      return 'success';
    }
  };

  return (
    <>
      <NumericFormat
        {...rest}
        allowLeadingZeros
        thousandSeparator=","
        className={`input-number ${messageError ? 'error' : ''} ${renderClass()}`}
      />
      {messageError && (
        <div className="error-validate">
          <Danger size="12" color="#f04438" variant="Bold" />
          <span>{messageError}</span>
        </div>
      )}
    </>
  );
};

export default InputCurrency;
