import React from 'react';

// import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import type { PasswordProps } from 'antd/es/input';

type IPropsInputPassword = {
  err?: boolean;
} & PasswordProps;

export const InputPassword: React.FC<IPropsInputPassword> = ({ err, ...rest }) => {
  // const renderSuffix = () => {
  //   if (err === undefined) return <span />;
  //   if (err) {
  //     return <CloseCircleOutlined />;
  //   } else {
  //     return <CheckCircleOutlined />;
  //   }
  // };

  const renderClass = () => {
    if (err === undefined) return '';
    if (err) {
      return 'error';
    } else {
      return 'success';
    }
  };

  return (
    <Input.Password
      {...rest}
      className={`input-normal ${renderClass()}`}
      maxLength={30}
      iconRender={(visible: boolean) =>
        visible ? (
          <Tooltip title="Hide">
            <EyeTwoTone />
          </Tooltip>
        ) : (
          <Tooltip title="Show">
            <EyeInvisibleOutlined />
          </Tooltip>
        )
      }
    />
  );
};

export default InputPassword;
