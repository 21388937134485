import { useCheckDevice, useToggleValue } from '@/hooks';
import { Layout } from 'antd';
import React from 'react';
import { Breadcrumbs } from '../Common';

import HeaderMobile from '@/components/Layout/HeaderMobile';
import { Sidebars } from './Sidebar';

interface IPropsLayoutMain {
  children: React.ReactNode;
}

export const LayoutMain: React.FC<IPropsLayoutMain> = ({ children }) => {
  const { Content } = Layout;
  const { isMobile } = useCheckDevice();
  const collapseStorage = isMobile ? 'false' : window.localStorage.getItem('collapse-menu') ?? 'false';
  const [collapsed, setCollapsed] = useToggleValue(JSON.parse(collapseStorage));
  return (
    <Layout>
      <Sidebars collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <div className="titan-header">
          <HeaderMobile />
          <Breadcrumbs />
        </div>
        <Content className="titan-layout__content">{children}</Content>
      </Layout>
    </Layout>
  );
};
