// eslint-disable-next-line import/no-extraneous-dependencies
import { InjectedConnector } from '@web3-react/injected-connector';
// eslint-disable-next-line import/no-extraneous-dependencies
import Web3 from 'web3';

// eslint-disable-next-line import/no-extraneous-dependencies
const ethers = require('ethers');

export const injected = new InjectedConnector({
  supportedChainIds: [97],
});

export async function getCurrentAccount() {
  try {
    const web3 = new Web3(window.ethereum);
    const result = await web3.eth.getAccounts();
    if (result) {
      return result;
    }
    return [];
  } catch (error) {
    console.log('error', error);
  }
}

export async function getBalance(abi, address, account) {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(abi, address);
  try {
    const balance = await contract.methods.balanceOf(account).call();
    if (balance) {
      return web3.utils.fromWei(balance, 'ether');
    }
    return 0;
  } catch (error) {
    console.log('error', error);
  }
}

export const signMetamask = async () => {
  try {
    if (!window.ethereum) throw new Error('No crypto wallet found. Please install it.');
    await window.ethereum.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage('toptradingbot');

    return {
      signature,
    };
  } catch (err) {
    return {
      signature: '',
      err,
    };
  }
};
