import { ApiHelper } from '@/utils';

import { IUser, IUserInList, IParamChangeStatusUser, IUserDetail } from './user.interface';
import { IListResponse, IQueryParam, IResponse } from '../common.services';

class UserGroupServiceRoute {
  static readonly USERS = '/me';

  static readonly LIST_USER = '/users';

  static readonly CHANGE_STATUS_USER = '/users/change-status';

  static readonly TOTAL_USER = '/users/total';
}

export const getUserProfile = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IResponse<IUser>>(UserGroupServiceRoute.USERS, undefined, query);
};

export const getListUser = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IUserInList>>(UserGroupServiceRoute.LIST_USER, undefined, query);
};

export const changeStatusUser = async (payload?: IParamChangeStatusUser) => {
  return ApiHelper.put<IResponse<IUserInList>, any>(UserGroupServiceRoute.CHANGE_STATUS_USER, payload);
};

export const getTotalUser = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IResponse<{ totalUser: number }>>(UserGroupServiceRoute.TOTAL_USER, undefined, query);
};

export const getUserDetail = async (id: number) => {
  return ApiHelper.get<IResponse<IUserDetail>>(`${UserGroupServiceRoute.LIST_USER}/${id}`);
};
