import React, { useEffect, useState } from 'react';

import { injected } from '@/utils/walletConnector';
import { Button, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Web3Provider } from '@ethersproject/providers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hooks, metaMask } from '@/connectors/metaMask';

import PopupNotification from '@/components/Common/PopupNotification';
import { useWeb3React } from '@web3-react/core';
import { CloseCircle } from 'iconsax-react';
type IProps = {
  pageTitle: string;
};

const { useIsActivating } = hooks;

const PageHeader: React.FC<IProps> = ({ pageTitle }) => {
  const context = useWeb3React<Web3Provider>();
  const { connector, account } = context;
  const { t } = useTranslation();
  const isActiving = useIsActivating();
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    metaMask.connectEagerly().catch((error) => {
      console.debug('Failed to connect eagerly to metamask');
    });
  }, []);

  const connectWallet = async () => {
    try {
      await connector.activate(injected);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {openDialog && <DialogSignOutMetamask open={openDialog} close={() => setOpenDialog(false)} />}
      <div className="titan-page-dashboard-header">
        <span className="title">{pageTitle}</span>
        <div className="connect-wallet">
          {account ? (
            <div className="action">
              <span className="code">
                {account.slice(0, 5)}...{account.slice(-5)}
              </span>
              <CloseCircle color="#475467" variant="Bold" onClick={() => setOpenDialog(true)} />
            </div>
          ) : (
            <>
              {isActiving ? (
                <Spin />
              ) : (
                <Button htmlType="submit" type="primary" onClick={connectWallet}>
                  {t('Connect wallet')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <Divider style={{ height: 1, backgroundColor: '#344054', margin: '16px 0px 20px 0px' }} />
    </>
  );
};

export const DialogSignOutMetamask = ({ open, close }: { open: boolean; close: () => void }) => {
  const context = useWeb3React<Web3Provider>();
  const { connector } = context;
  return (
    <PopupNotification
      isOpen={open}
      onClose={close}
      title="Disconnect wallet"
      type="warning"
      desc="Disconnecting this wallet disables token withdrawal. Are you sure you want to disconnect this wallet?"
      btnTextConfirm="Disconnect"
      onConfirm={() => {
        close();
        if (connector?.deactivate) {
          void connector.deactivate();
        } else {
          void connector.resetState();
        }
      }}
    />
  );
};

export default PageHeader;
