import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import httpStatus from '@/commons/httpStatus';
import { errorCode, ErrorCodeLogin } from '@/constants';
import { IParamLogin, loginAuth, logout } from '@/services/auth';
import { getUserProfile, IUser } from '@/services/user';

import history from '../history';
import { fetchProfileUser } from '../user/saga';
import { UserActionTypes } from '../user/types';
import { userLoginRequestError, userLoginRequestSuccess } from './actions';
import { AuthActionTypes } from './types';
interface IActionLoginSuccess {
  type: string;
  payload: IUser;
}

interface IAction {
  type: string;
  payload: IParamLogin | string;
}

function forwardTo(location: string) {
  history.push(location);
}

function* handleErrorCodeLogin(payload: any) {
  if (payload.statusCode === httpStatus.StatusBadRequest) {
    const payloadErrCode = payload.errorCode;
    const messageError = errorCode?.[payloadErrCode as keyof typeof errorCode];
    const messageFull = `${messageError} ${
      payloadErrCode === ErrorCodeLogin.INCORRECT_PASSWORD
        ? `You have ${payload?.args?.numberOfAttemptsLeft} attempts before your account is locked`
        : ''
    }`;
    yield put(userLoginRequestError(messageFull));
  }
  if (payload.statusCode === httpStatus.StatusNotFound) {
    yield put(userLoginRequestError(payload.message));
  } else {
    const payloadErrCode = payload.errorCode;
    const messageError = errorCode?.[payloadErrCode as keyof typeof errorCode];
    yield put(userLoginRequestError(messageError));
  }
}

function* loginNormal(action: IAction): any {
  try {
    const payloadParam = action.payload as IParamLogin;
    const payload = yield call(async () => loginAuth(payloadParam));

    if (payload.data && payload.success) {
      yield put(userLoginRequestSuccess(payload.data));
    } else {
      yield call(() => handleErrorCodeLogin(payload));
    }
  } catch (error: any) {
    console.log('-------------------------: error', error);
    return yield put(userLoginRequestError(error.data.message));
  }
}

function* processingUserLoginSuccess(action: IActionLoginSuccess): any {
  const currentUser = action.payload;
  window.localStorage.setItem('jwt', currentUser?.accessToken ?? '');
  window.localStorage.setItem('refresh_token', currentUser?.refreshToken ?? '');
  let redirectPage = '/user-management/dashboard';
  const payloadUserProfile = yield call(getUserProfile);
  let rd: any = new URLSearchParams(window.location.search).get('rd');
  if (payloadUserProfile.success) {
    const roleUser = yield window.localStorage.getItem('role');
    if (!roleUser) {
      yield window.localStorage.setItem('role', payloadUserProfile.data?.role);
    }

    redirectPage = payloadUserProfile.data?.role === 'creator' ? '/help-center' : '/user-management/dashboard';
    if (payloadUserProfile.data?.role === 'creator') {
      rd = undefined;
    }
  }

  yield call(forwardTo, rd ?? redirectPage);
}

function* doLogout() {
  try {
    yield call(async () => logout());
    yield put({ type: AuthActionTypes.USER_LOGOUT });
  } catch (e) {
    console.log('Error logging out', e);
  }
}

export const authSagas = [
  takeEvery(UserActionTypes.REQUEST_USER_PROFILE, fetchProfileUser),
  takeLatest(AuthActionTypes.REQUEST_LOGIN_USER, loginNormal),
  takeEvery(AuthActionTypes.REQUEST_LOGIN_USER_SUCCESS, processingUserLoginSuccess),
  takeEvery(AuthActionTypes.REQUEST_USER_LOGOUT, doLogout),
];
