import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { history } from './store';
import { persistor, store } from './store/configureStore';

import { metaMask, hooks as metaMaskHooks } from '@/connectors/metaMask';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import type { MetaMask } from '@web3-react/metamask';
const connectors: [MetaMask, Web3ReactHooks][] = [[metaMask, metaMaskHooks]];

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Web3ReactProvider connectors={connectors}>
          <App />
        </Web3ReactProvider>
      </Router>
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
