import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const useOutsideClick = () => {
  const ref = useRef<any>(null);
  const history = useHistory();
  const { isTour, tourHeader } = queryString.parse(history.location.search);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClickOutside = () => {
    setIsPopupOpen(false);
  };

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        (event.target as Node)?.parentElement?.classList.contains('ant-tour-inner') ||
        (event.target as Node).parentNode?.parentElement?.classList.contains('ant-tour-mask') ||
        (event.target as Node).parentNode?.parentElement?.classList.contains('ant-tour-inner') ||
        isTour ||
        tourHeader
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return {
    ref,
    isPopupOpen,
    togglePopup,
    setIsPopupOpen,
  };
};

export default useOutsideClick;
