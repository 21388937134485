import { LogoImg, TickCircleIcon } from '@/assets';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const LoginLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="login-layout">
      <div className="signin-page">
        <div className="signin-content">
          <a href="/sign-in">
            <img src={LogoImg} alt="" />
          </a>

          <div className="signin-content-text">
            <p className="signin-content-text__title">{t('common.login_left_side_title')}</p>
            <p className="signin-content-text__desc">{t('common.login_left_side_desc')}</p>
            <ul className="signin-content-list">
              <li className="signin-content-list-item">
                <TickCircleIcon />
                <span>{t('Increased Feasibility and Efficiency')}</span>
              </li>
              <li className="signin-content-list-item">
                <TickCircleIcon />
                <span>{t('Elimination of Emotional Factors')}</span>
              </li>
              <li className="signin-content-list-item">
                <TickCircleIcon />
                <span>{t('Market Monitoring and Analysis')}</span>
              </li>
            </ul>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
