import React from 'react';

import { LoginLayout, MainLayout } from '../layouts';
import {
  WITHDRAWAL_REQUESTS_ROUTER,
  COMMISSION_REPORT_ROUTER,
  USER_LIST_ROUTER,
  MEMBER_LIST_ROUTER,
  USER_DETAIL_ROUTER,
  MEMBER_DETAIL_ROUTER,
  HISTORY_LIST_ROUTER,
  VIP_PACKAGE_TRAKING_ROUTER,
  HELP_CENTER_SETTING_ROUTER,
  NEW_ARTICLE_ROUTER,
  ADMIN_TRANSACTION_HISTORY_ROUTER,
  BALANCE_REPORT_ROUTER,
  SALE_REPORT_ROUTER,
  WALLET_LIST_ROUTER,
  USER_TRANSACTION_HISTORY_ROUTER,
  TRANSACTION_REQUEST_TRACKING_ROUTER,
  DASHBOARD_ROUTER,
  AFFILIATE_MANAGEMENT,
  TASK_MANAGEMENT,
  REWARD_REQUEST,
} from './constants';
import { UserRole } from '@/constants';

//new
const Login = React.lazy(() => import('@/pages/sign-in'));

const Dashboard = React.lazy(() => import('@/pages/user-management/dashboard'));
const UserList = React.lazy(() => import('@/pages/user-management/user-list'));
const UserDetail = React.lazy(() => import('@/pages/user-management/user-list/UserDetail'));
const MemberList = React.lazy(() => import('@/pages/user-management/member-list'));
const MemberDetail = React.lazy(() => import('@/pages/user-management/member-list/MemberDetail'));

const WithdrawalRequests = React.lazy(() => import('@/pages/request-tracking/withdrawal-requests'));
const CommissionReport = React.lazy(() => import('@/pages/reports/commission-report'));
const BalanceReport = React.lazy(() => import('@/pages/reports/balance-report'));
const SaleReport = React.lazy(() => import('@/pages/reports/sale-report'));
const HistoryList = React.lazy(() => import('@/pages/history-log/history-list'));
const VipPackageTracking = React.lazy(() => import('@/pages/request-tracking/vip-package-tracking'));

const HelpCenter = React.lazy(() => import('@/pages/help-center'));
const NewArticle = React.lazy(() => import('@/pages/help-center/new-article'));

const UserTransactionHistory = React.lazy(() => import('@/pages/hot-wallet/user-transaction-history'));
const AdminTransactionHistory = React.lazy(() => import('@/pages/hot-wallet/admin-transaction-history'));
const WalletList = React.lazy(() => import('@/pages/hot-wallet/wallet-list'));
const TransactionRequestTracking = React.lazy(() => import('@/pages/hot-wallet/transaction-request-tracking'));

const AffiliateManagement = React.lazy(() => import('@/pages/affiliate-management'));
const TaskManagementPage = React.lazy(() => import('@/pages/reward-management/task-management'));
const RewardRequestPage = React.lazy(() => import('@/pages/reward-management/reward-request'));

export interface IRoute {
  Component: ((props: any) => JSX.Element) | React.FC<any>;
  Layout: ((props: any) => JSX.Element) | React.FC<any>;
  Protected: boolean;
  isHelpcenter?: boolean;
  path?: string | string[];
  routePath?: string;
  from?: string;
  to?: string;
  exact?: boolean;
  roles: string[];
}

const routesDefault: IRoute[] = [
  {
    Component: Login,
    Layout: LoginLayout,
    exact: true,
    path: '/sign-in',
    routePath: '/sign-in',
    Protected: false,
    roles: [],
  },
  // {
  //   Component: ConfirmCode,
  //   Layout: LoginLayout,
  //   exact: true,
  //   path: '/confirm-code',
  //   routePath: '/confirm-code',
  //   Protected: false,
  //   roles: [],
  // },

  // {
  //   Component: ForgotPassword,
  //   Layout: LoginLayout,
  //   exact: true,
  //   path: '/forgot-password',
  //   routePath: '/forgot-password',
  //   Protected: false,
  //   roles: [],
  // },
  // {
  //   Component: ResetPassword,
  //   Layout: LoginLayout,
  //   exact: true,
  //   path: '/reset-password',
  //   routePath: '/reset-password',
  //   Protected: false,
  //   roles: [],
  // },
  {
    Component: Dashboard,
    Layout: MainLayout,
    exact: true,
    path: [DASHBOARD_ROUTER],
    routePath: DASHBOARD_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
  },

  //User list
  {
    Component: UserList,
    Layout: MainLayout,
    exact: true,
    path: [USER_LIST_ROUTER],
    routePath: USER_LIST_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
  },
  {
    Component: UserDetail,
    Layout: MainLayout,
    exact: true,
    path: [USER_DETAIL_ROUTER],
    routePath: USER_DETAIL_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
  },
  //

  //Member list
  {
    Component: MemberList,
    Layout: MainLayout,
    exact: true,
    path: [MEMBER_LIST_ROUTER],
    routePath: MEMBER_LIST_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
  },
  {
    Component: MemberDetail,
    Layout: MainLayout,
    exact: true,
    path: [MEMBER_DETAIL_ROUTER],
    routePath: MEMBER_DETAIL_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Marketing],
  },
  //

  //Withdrawal requests
  {
    Component: WithdrawalRequests,
    Layout: MainLayout,
    exact: true,
    path: [WITHDRAWAL_REQUESTS_ROUTER],
    routePath: WITHDRAWAL_REQUESTS_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Vip package tracking
  {
    Component: VipPackageTracking,
    Layout: MainLayout,
    exact: true,
    path: [VIP_PACKAGE_TRAKING_ROUTER],
    routePath: VIP_PACKAGE_TRAKING_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Commission report
  {
    Component: CommissionReport,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSION_REPORT_ROUTER],
    routePath: COMMISSION_REPORT_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //History list
  {
    Component: HistoryList,
    Layout: MainLayout,
    exact: true,
    path: [HISTORY_LIST_ROUTER],
    routePath: HISTORY_LIST_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },

  //

  // Help center
  {
    Component: HelpCenter,
    Layout: MainLayout,
    exact: true,
    isHelpcenter: true,
    path: [HELP_CENTER_SETTING_ROUTER],
    routePath: HELP_CENTER_SETTING_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Creator],
  },
  {
    Component: NewArticle,
    Layout: MainLayout,
    exact: true,
    isHelpcenter: true,
    path: [NEW_ARTICLE_ROUTER],
    routePath: NEW_ARTICLE_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin, UserRole.Creator],
  },

  //Transaction request tracking
  {
    Component: TransactionRequestTracking,
    Layout: MainLayout,
    exact: true,
    path: [TRANSACTION_REQUEST_TRACKING_ROUTER],
    routePath: TRANSACTION_REQUEST_TRACKING_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //User transaction history
  {
    Component: UserTransactionHistory,
    Layout: MainLayout,
    exact: true,
    path: [USER_TRANSACTION_HISTORY_ROUTER],
    routePath: USER_TRANSACTION_HISTORY_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Admin transaction history
  {
    Component: AdminTransactionHistory,
    Layout: MainLayout,
    exact: true,
    path: [ADMIN_TRANSACTION_HISTORY_ROUTER],
    routePath: ADMIN_TRANSACTION_HISTORY_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Wallet list
  {
    Component: WalletList,
    Layout: MainLayout,
    exact: true,
    path: [WALLET_LIST_ROUTER],
    routePath: WALLET_LIST_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Balance report
  {
    Component: BalanceReport,
    Layout: MainLayout,
    exact: true,
    path: [BALANCE_REPORT_ROUTER],
    routePath: BALANCE_REPORT_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  //

  //Sale report
  {
    Component: SaleReport,
    Layout: MainLayout,
    exact: true,
    path: [SALE_REPORT_ROUTER],
    routePath: SALE_REPORT_ROUTER,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  // Affiliate
  {
    Component: AffiliateManagement,
    Layout: MainLayout,
    exact: true,
    path: [AFFILIATE_MANAGEMENT],
    routePath: AFFILIATE_MANAGEMENT,
    Protected: true,
    roles: [UserRole.SuperAdmin],
  },
  {
    Component: TaskManagementPage,
    Layout: MainLayout,
    exact: true,
    path: [TASK_MANAGEMENT],
    routePath: TASK_MANAGEMENT,
    Protected: true,
    roles: [UserRole.Marketing, UserRole.SuperAdmin],
  },
  {
    Component: RewardRequestPage,
    Layout: MainLayout,
    exact: true,
    path: [REWARD_REQUEST],
    routePath: REWARD_REQUEST,
    Protected: true,
    roles: [UserRole.Marketing, UserRole.SuperAdmin],
  },
];

export const routes = routesDefault;
