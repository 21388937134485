import queryString from 'query-string';

import {
  FormatTime,
  ObjMemberGradeFilter,
  errorCode,
  listAcitivityLogModule,
  listStatusAffiliateRequest,
  listStatusClaimRequest,
  listStatusUser,
} from '@/constants';
import moment from 'moment';
import { listCountry } from '@/constants/country';

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password: string) => {
  return String(password).match(/^(?=.*\d)(?=.*[A-Z]).{8,30}/);
};

export const handleErrorCode = (response: any) => {
  return errorCode[response.errorCode as keyof typeof errorCode];
};

export const modifyDirectionOrder = (value: string | undefined) => {
  if (value === 'ascend') return 'ASC';
  if (value === 'descend') return 'DESC';
  return '';
};
export const revertDirectionOrder = (value: string) => {
  if (value === 'ASC') return 'ascend';
  return 'descend';
};

export const handleValueFilter = (value: string | undefined) => {
  if (value === 'all') return '';
  return value;
};

export const handleStringify = (obj: any, history: any) => {
  const stringifySymbol = queryString.stringify(obj, { skipEmptyString: true });
  history.push({ pathname: window.location.pathname, search: stringifySymbol });
};

let b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''),
  );

export const parseJwt = (token: string) =>
  JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));

export const handleNumber = (value: number | undefined) => {
  if (!value || value === 0) {
    return '';
  }
  if (value > 0) {
    return '+';
  }
};

export const hideEmail = (email: string) => {
  return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      // eslint-disable-next-line no-param-reassign
      gp2 += '*';
    }
    return gp2;
  });
};

export const formatCurrencyToNumber = (value: string) => {
  if (value) {
    return Number(value.replace(/[^0-9.-]+/g, ''));
  }
  return null;
};

export const convertLocalToUTCDate = (date: string, endDate?: boolean) => {
  if (!date) {
    return date;
  }
  let newDate = new Date(date);
  let utcDate = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0);
  if (endDate) {
    utcDate = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59);
  }
  newDate = new Date(utcDate);
  return newDate.toUTCString();
};

export const convertUTCToLocalDate = (date: string, endDate?: boolean) => {
  if (!date) {
    return date;
  }
  let newDate = new Date(date);
  let utcDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 0, 0, 0);
  if (endDate) {
    utcDate = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), 23, 59, 59);
  }
  newDate = new Date(utcDate);
  return newDate;
};

export const formatDateTime = (date: string | moment.Moment = '', hasTime: boolean = false) => {
  return (
    date &&
    moment
      .utc(date)
      .local()
      .format(hasTime ? FormatTime.DateFull : FormatTime.Date)
  );
};

export const getStatusUserByStatusKey = (key: string) => {
  const result = listStatusUser.find((i) => i.key === key);
  return result?.name;
};

export const getMemberGradeByKey = (key: string) => {
  const result = ObjMemberGradeFilter.items.find((i) => i.key === key);
  return result?.name || '-';
};

export const formatExchange = (arr: string[]) => {
  let text = '';
  arr.forEach((element, idx) => {
    text = text + `${element.charAt(0).toUpperCase() + element.slice(1)}`;
    if (idx < arr.length - 1) {
      text = text + `, `;
    }
  });
  return text;
};

export const getStatusClaimRequestByStatusKey = (key: number) => {
  const result = listStatusClaimRequest.find((i) => i.key === key);
  return result;
};

export const getAcitivityLogModuleName = (key: string) => {
  const result = listAcitivityLogModule.find((i) => i.key === key);
  return result?.name;
};

export const truncateMiddleText = (text: string, ellipsis = '...', start = 6, end = 6): string => {
  if (text?.length > start + end) {
    return text.substr(0, start) + ellipsis + text.substr(end * -1);
  }

  return text;
};

export const getAffiliateRequestByStatusKey = (key: string) => {
  const result = listStatusAffiliateRequest.find((i) => i.key === key);
  return result;
};

export function capitalizeFirstLetter(str: any) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}
export function getCountryName(key: any) {
  const findCountryName = listCountry.find((i) => i.code === key);
  return findCountryName?.name || '-';
}

export const defaultDate = (day: number = 29) => {
  const endDate = moment().utc().endOf('day');

  const startDate = moment().utc().subtract(day, 'days').startOf('day');

  return {
    startTime: String(startDate.unix()),
    endTime: String(endDate.unix()),
  };
};
