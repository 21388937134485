import PopupCustom from '@/components/Common/PopupCustom';
import { Button } from 'antd';
import { CloseCircle, InfoCircle, TickCircle } from 'iconsax-react';
import React from 'react';

interface IPopupNotification {
  isOpen: boolean;
  onClose: () => void;
  type: 'success' | 'error' | 'warning';
  title: string;
  desc?: string;
  btnTextConfirm?: string;
  onConfirm?: () => void;
  textarea?: string;
  onChangeTextarea?: (e: React.ChangeEventHandler<HTMLTextAreaElement>) => void;
}

const PopupNotification = ({
  isOpen,
  onClose,
  type,
  title,
  desc,
  btnTextConfirm,
  textarea,
  onChangeTextarea,
  onConfirm,
}: IPopupNotification) => {
  return (
    <PopupCustom isOpen={isOpen} onClose={onClose} className="popup-notification">
      <div className="heading-popup">
        {type === 'success' && <TickCircle size="40" color="#12b76a" variant="Bulk" />}
        {type === 'error' && <CloseCircle size="40" color="#fdb022" variant="Bulk" />}
        {type === 'warning' && <InfoCircle size="40" color="#fdb022" variant="Bulk" />}
      </div>
      <div className="popup-notification__content">
        <p className="title">{title}</p>
        {desc && <p className="desc">{desc}</p>}
        {textarea && <textarea className="textarea" value={textarea} />}
      </div>
      <div className="footer-popup-action">
        <Button className="btn-gray-700" onClick={onClose}>
          Cancel
        </Button>
        <Button className={type === 'success' ? 'btn-primary' : 'btn-warning-600'} onClick={onConfirm}>
          {btnTextConfirm}
        </Button>
      </div>
    </PopupCustom>
  );
};

export default React.memo(PopupNotification);
