import { createAction } from '@reduxjs/toolkit';

import { UserActionTypes } from './types';

const initProfileState = {
  id: null,
  name: '',
  email: '',
  role: '',
  position: '',
  lastClaimTime: '',
  divisionId: null,
  divisionName: null,
  avatar: null,
  commissionRate: null,
  teamName: null,
  teamId: null,
  isClaimable: null,
  externalWallet: '',
};

export const initProfileFullState = {
  profile: initProfileState,
  is_loading: false,
  error: null,
};

export const requestUserProfile = createAction(UserActionTypes.REQUEST_USER_PROFILE);

export const requestUserProfileSuccess = createAction(UserActionTypes.REQUEST_USER_PROFILE_SUCCESS);

export const requestUserProfileError = createAction(UserActionTypes.REQUEST_USER_PROFILE_ERROR);

export const emailUserForgotPassword = createAction(UserActionTypes.EMAIL_USER_FORGOT_PASSWORD);

export type TUserProfileState = typeof initProfileState;
export type TUserProfileFullState = typeof initProfileFullState;
